import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {truncate} from "lodash/string";
import GlobalHelper from "../../../helpers/global-helper";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import LotQuickviewImg from "../lot-thumbnail";
import {setDataImageGallery} from "../../../redux/modals/modal-image-gallery/actionImageGallery";
import {Link, useNavigate} from "react-router-dom";
import {SET_LOT_DATA} from "../../../redux/types";

const AdvertisingLot = (props) => {

    const {
        primary_image, title,
        message, balance, lot_type, top_bid, min_bid
    } = props

    const {
        visitor: {permissions: {auctions: {see_new = false}}}, phrases:{ak_au_phrase_lot_balance}} = useSelector((state) => {
        return state.lots
    })

    const phrase_many_lot = (lot_type === 1 && balance > 0) ?
        ak_au_phrase_lot_balance.replace(/\{balance\}/, balance) : null;

    const title_short = truncate(title, {'length': 45, 'separator': '...'});
    const description = truncate(message, {'length': 100, 'separator': '...'});

    const link = `/lots/${GlobalHelper.getLotVariableReference(props)}`;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleTitleClick = (event) => {
        if (see_new) { //todo delete this
            event.preventDefault();
            navigate(link);
        }
    }

    const LotTitle = () => {
        if (see_new) {
            return (
                <Link className={'font-weight-bold text-rg overflow-auto text-break'} to={{
                    pathname: `${link}`
                }} onClick={() => {
                    dispatch({type: SET_LOT_DATA, payload: {auction: props}});
                }}>
                    {title_short} {phrase_many_lot}
                </Link>
            )
        }

        return (
            <a className='font-weight-bold text-rg overflow-auto text-break'
               onClick={(e) => handleTitleClick(e)}
               href={link} rel="nofollow">
                {title_short} {phrase_many_lot}</a>
        )
    }



    const cost = (top_bid === 0 || top_bid === null) ? min_bid : top_bid;

    const showImage = () => {
        dispatch(setDataImageGallery({modalShow: true, attachments: [{...primary_image}]}))
    }

    return (
        <div className="lots-block m-1 border rounded">
            <div className="row no-gutters">
                <div className="col-xl-4">
                    <LotQuickviewImg
                        linkUrl={link}
                        imgUrl={GlobalHelper.setImg(primary_image, true)}
                        title={title}
                        rel={'nofollow'}
                        setShow={showImage}
                    />
                </div>

                <OverlayTrigger
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-moreLink`}>
                            {message}
                        </Tooltip>
                    }>
                    <div className="col-xl-8  align-self-center">
                        <div className="card-body p-1">
                            <div className="d-flex flex-wrap mb-1">
                                <LotTitle/>
                            </div>
                            <small className='text-break'>{description}</small>
                        </div>
                    </div>
                </OverlayTrigger>
            </div>
            <div className="price card-text text-right">
                <p className="font-weight-bolder mx-2 my-1">
                    {GlobalHelper.currencyFormat(cost)}
                </p>
            </div>
        </div>
    )
}

export default AdvertisingLot